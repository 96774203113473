import request from '@/utils/enterpriseRequest'

//添加购物车
export async function addShoppingCart(data) {
    return await request({
        url: '/business-server/Order/InsertOrderCart',
        method: 'post',
        data
    })
}

//查看购物车
export async function getShoppingCartSum(data) {
    return await request({
        url: '/business-server/Order/OrderCartList',
        method: 'post',
        data
    })
}

//获取支付二维码
export async function getOrderCode(data) {
    return await request({
        url: '/business-server/Payment/getOrderCode',
        method: 'post',
        data
    })
}

//查询订单支付状态
export async function queryPaymentState(data) {
    return await request({
        url: '/business-server/Payment/queryPaymentState',
        method: 'post',
        data
    })
}

//查询订单支付信息
export async function paymentRecordList(data) {
    return await request({
        url: '/business-server/Payment/paymentRecordList',
        method: 'post',
        data
    })
}
