import axios from 'axios'
import Cookies from "js-cookie";
import store from '@/store';


// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url 
    // baseURL: 'http://192.168.1.156:20003', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})

service.interceptors.request.use(config => {
    config.headers['Region'] = "SG"
    // config.headers['Region'] = "QG"
    // var token = "464F9D35-7724-4FC0-85D6-A6E7EFF6E3DA"

    let token = Cookies.get("KAI_HUA_ADMIN_TOKEN");

    token && (config.headers.common['Authorization'] = `Bearer ${token}`)

    // config.headers['Operator-Identity-Id'] = "735C006AEE7E47F6AD42095DF16C26D2"

    token && (config.headers['Operator-Identity-Id'] = store.state.user.id)

    return config
},
    err => {
        console.log(err)
        return Promise.reject(err)
    });

export default service
