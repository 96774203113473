import request from '@/utils/request'

//活动
export async function getActivity(data) {
    return await request({
        url: '/business-server/Activity/homeActivity',
        method: 'post',
        data
    })
}

//推荐活动商品
export async function getActivityGoods(data) {
    return await request({
        url: '/business-server/Activity/homeActivityComm',
        method: 'post',
        data
    })
}

// 店铺活动
export async function getStoreActivity(data) {
    return await request({
        url: '/business-server/Activity/homeStoreActivityList',
        method: 'post',
        data
    })
}