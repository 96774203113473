import request from '@/utils/enterpriseRequest'


export async function getEnterpriseId(data) {
    return await request({
        url: '/business-server/BusinessUser/findCurrentIdentityEnterpriseId',
        method: 'post',
        data
    })
}

export async function getCurrUser(data) {
    return await request({
        url: '/org-server/User/getCurrUser',
        method: 'post',
        data
    })
}