import { getUser, getShoppingCartSum, getUserData } from "@/api/PersonalUser"

export default {
	namespaced: true,
	state: {
		userId: "", //消费者id
		userInfo: { userEntity: { name: null }, userDetailEntity: { profilePicture: null } }, //消费者信息
		userLogin: false,  //消费者登录
		userSum: 0 // 商品数量
	},
	mutations: {
		setUserId(state, payload) {
			state.userId = payload
			state.userLogin = true
		},
		//用户信息
		setUser(state, payload) {
			state.userInfo = payload
		},
		getCartSum(state, payload) {
			state.userSum = payload
		},
	},
	actions: {
		async getUserInfo(ctx, addValue) {
			let user = await getUser({});
			ctx.commit("setUserId", user.data.data.id);
			let userInfo = await getUserData({
				object: {
					id: ctx.state.userId,
				},
			});
			ctx.commit("setUser", userInfo.data.data.userInfo)
		},

		getShoppingCartSum(ctx, addValue) {
			let a = setTimeout(async () => {
				let shop = await getShoppingCartSum({
					object: {
						userId: ctx.state.userId,
					},
					pageNum: 0,
					pageSize: 0,
				});
				shop.data.data && ctx.commit("getCartSum", shop.data.data.sum)
				clearTimeout(a)
			}, 200);
		}


	}

}
