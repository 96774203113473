import request from '@/utils/classRequest.js'
//分类
export async function Classification(data) {
  return await request({
    url: '/platform-management-server/Classify/queryCategoriesCatalog',
    method: 'post',
    data
  })
}

//新闻列表
export async function News(data) {
  return await request({
    url: '/platform-management-server/News/PublishedNewsList',
    method: 'post',
    data
  })
}
//新闻详情
export async function NewsDetails(data) {
  return await request({
    url: '/platform-management-server/News/NewsDetails',
    method: 'post',
    data
  })
}