import { getCurrUser } from "@/api/User"
import { getShoppingCartSum } from "@/api/Order"
export default {
  namespaced: true,
  state: {
    isLogin: false,
    userData: {
      userEntity: {
        name: ''
      }
    },//用户的数据
    id: "",
    brandId: "",
    sum: 0
  },
  mutations: {
    setUserInfo(state, payload) {
      state.isLogin = true
      state.userData = payload.userInfo
      state.id = payload.identityDtoList[0].identityEntity.id
    },
    setBrandId(state, payload) {
      state.brandId = payload
    },
    setSum(state, payload) {
      state.sum = payload
    },
  },
  actions: {

    async getUser(context) {
      const data = await getCurrUser({ object: {} });
      context.commit('setUserInfo', data.data.data);
    },
    async getCartSum(context) {
      const data = await getShoppingCartSum({
        object: {
          enterpriseId: context.state.brandId
        },
        pageNum: 0,
        pageSize: 0,
      });
      context.commit('setSum', data.data.total);
    },

  }

}
