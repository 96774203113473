
import request from '@/utils/request'

//首页商品列表
export async function BrandDetails(data) {
  return await request({
    url: '/business-server/HomePage/brandInfo',
    method: 'post',
    data
  })
}

//全部品牌列表
export async function BrandInitialsList(data) {
  return await request({
    url: '/business-server/HomePage/brandInitialsList',
    method: 'post',
    data
  })
}

// 商品列表 所有品牌

export async function AllBrand(data) {
  return await request({
    url: '/business-server/HomePage/findBrandInfoList',
    method: 'post',
    data
  })
}
