import request from '@/utils/request'

//种植基地
export async function getPlantationBase(data) {
    return await request({
        url: '/business-server/Detect02/queryPlantationBaseById',
        method: 'post',
        data
    })
}

//检测认证
export async function getdetectionPdf(data) {
    return await request({
        url: '/business-server/Detect02/queryDetect',
        method: 'post',
        data
    })
}

//有机认证
export async function getOrganicDetails(data) {
    return await request({
        url: '/business-server/action/queryOrganicDetails',
        method: 'post',
        data
    })
}

//工厂
export async function getFactoryList(data) {
    return await request({
        url: '/business-server/action/queryFactoryList',
        method: 'post',
        data
    })
}

//保险
export async function getInsuranceDetails(data) {
    return await request({
        url: '/business-server/action/queryInsuranceDetails',
        method: 'post',
        data
    })
}