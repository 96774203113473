import request from '@/utils/request'

//店铺首页
export async function getStoreHomePage(data) {
  return await request({
    url: '/business-server/HomePage/StoreHomePage',
    method: 'post',
    data
  })
}
//商品详情
export async function getCommodityDetails(data) {
  return await request({
    url: '/business-server/CommodityMgt/CommodityDetails',
    method: 'post',
    data
  })
}
//商品价格详情
export async function getCommodityPriceDetails(data) {
  return await request({
    url: '/business-server/CommodityMgt/CommodityPriceDetails',
    method: 'post',
    data
  })
}
//单品价格详情
export async function getCommodityCodePriceDetails(data) {
  return await request({
    url: '/business-server/CommodityMgt/CommodityCodePriceDetails',
    method: 'post',
    data
  })
}
//店铺轮播图
export async function getStoreBanner(data) {
  return await request({
    url: '/business-server/HomePage/storeSlideshow',
    method: 'post',
    data
  })
}
//店铺类目
export async function getStoreCategory(data) {
  return await request({
    url: '/business-server/Classify/queryStoreIdCategoriesCatalog',
    method: 'post',
    data
  })
}