
export default {
    namespaced: true,
    state: {
        title: []

    },
    mutations: {
        setName(state, payload) {
            state.title.push(payload)
        },
    },
    actions: {


    }

}
