/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {number} value
 * @returns {string}
 */
export function formatDate(value) {// 时间戳转换日期格式方法
  if (value == null) {
    return '';
  } else {
    let date = new Date(value);
    let y = date.getFullYear();// 年
    let MM = date.getMonth() + 1;// 月
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();// 日
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();// 时
    h = h < 10 ? ('0' + h) : h;
    let m = date.getMinutes();// 分
    m = m < 10 ? ('0' + m) : m;
    let s = date.getSeconds();// 秒
    s = s < 10 ? ('0' + s) : s;
    return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
  }
}

/**
 * @param {number} value
 * @returns {string}
 */
export function formatDay(value) {// 时间戳转换日期格式方法
  if (value == null) {
    return '';
  } else {
    let date = new Date(value);
    let y = date.getFullYear();// 年
    let MM = date.getMonth() + 1;// 月
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();// 日
    d = d < 10 ? ('0' + d) : d;
    return y + '年' + MM + '月' + d + '日'
  }
}


export function price(num) {
  let now = new Date();
  let m = now.getTime(); //当前时间戳
  if (num.preferentialEndTime === null) {
    //没有特惠时间
    if (num.tradePrice === null) {
      //没有批发价
      return "-";
    } else if (String(Number(num.tradePrice)).indexOf(".")) {
      // return num;
      return num.tradePrice.toFixed(2); //没有小数点,返回两位小数
    } else {
      return num.tradePrice; //返回批发价
    }
  } else {
    let preferential = m - num.preferentialEndTime; //计算优惠时间是否超过当前时间
    if (preferential > 0) {
      //当前时间戳大于优惠时间说明优惠过期
      if (num.tradePrice === null) {
        //没有批发价,返回零售价
        if (String(Number(num.retailPrice)).indexOf(".")) {
          return num.retailPrice.toFixed(2);
        } else {
          return num.retailPrice;
        }
      } else if (String(Number(num.tradePrice)).indexOf(".")) {
        return num.tradePrice.toFixed(2);
      } else {
        return num.tradePrice;
      }
    } else {
      if (
        num.preferentialTradePrice === null &&
        num.preferentialRetailPrice === null
      ) {
        //没有特惠价格
        return "-";
      } else if (num.preferentialTradePrice) {
        //有特惠批发价
        if (String(Number(num.preferentialTradePrice)).indexOf(".")) {
          return num.preferentialTradePrice.toFixed(2);
        } else {
          return num.preferentialTradePrice;
        }
      } else {
        //有特惠零售价
        if (String(Number(num.preferentialRetailPrice)).indexOf(".")) {
          return num.preferentialRetailPrice.toFixed(2);
        } else {
          return num.preferentialRetailPrice;
        }
      }
    }
  }
}
/**
 * @param {object} value
 * @param {string} field
 * @returns {number}
 */
 export function preferential(value, field) {
  let now = new Date();
  let m = now.getTime();
  if (value.preferentialStartTime === null) {
    if (field === 'retailPrice') {
      return value.retailPrice.toFixed(2);
    } else if (field === 'tradePrice') {
      return value.tradePrice.toFixed(2);
    }
  }

  // console.log(new Date(value.preferentialStartTime).toLocaleString());
  // console.log(new Date(m).toLocaleString());
  // console.log(new Date(value.preferentialEndTime).toLocaleString());

  if (value.preferentialStartTime < m && m < value.preferentialEndTime) {
    //优惠中
    if (field === 'retailPrice') {
      return value.preferentialRetailPrice.toFixed(2);
    } else if (field === 'tradePrice') {
      return value.preferentialTradePrice.toFixed(2);
    }
  } else {
    //无优惠
    if (field === 'retailPrice') {
      return value.retailPrice.toFixed(2);
    } else if (field === 'tradePrice') {
      return value.tradePrice.toFixed(2);
    }
  }
}
/**
 * @param {string,number} path
 * @returns {string}
 */
export function decimals(num) {
  if (num === null) {
    return "-";
  } else if (num == 0) {
    return num;
  } else if (String(num).indexOf(".")) {
    // return num;
    return num.toFixed(2);
  } else {
    return num;
  }
}



/**
 * @param {number} value
 * @returns {string}
 */
export function countDown(value) {
  if (value == null) {
    return '';
  } else {
    // console.log(value );
    let EndTime = new Date(value); //截止时间 
    let NowTime = new Date();

    // console.log(NowTime.getTime() - EndTime.getTime());

    let t = 432000000 - (NowTime.getTime() - EndTime.getTime());
    let d = Math.floor(t / 1000 / 60 / 60 / 24);
    let h = Math.floor(t / 1000 / 60 / 60 % 24);
    let m = Math.floor(t / 1000 / 60 % 60);
    let s = Math.floor(t / 1000 % 60);
    h = h < 10 ? ('0' + h) : h;
    m = m < 10 ? ('0' + m) : m;
    s = s < 10 ? ('0' + s) : s;

    return `${d}天${h}小时${m}分${s}秒`;

    // 设置元素的文本内容
  }
}
