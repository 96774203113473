<template>
  <div id="footer" v-if="$route.name != 'mall'" ref="footer">
    <div class="footer-nav">
      <div>
        <div class="nav-list">
          <ul v-for="(item, index) in nav" :key="index">
            <p>{{ item.title }}</p>
            <li
              v-for="(list, i) in item.children"
              :key="i"
              @click="operation(index, i)"
            >
              <a :href="list.href" target="_blank" rel="noopener noreferrer">{{
                list.title
              }}</a>
            </li>
          </ul>
        </div>
        <div class="QR-code">
          <img v-lazy="require('@/assets/images/QR-code.png')" alt="" />
          <p>关注官方微信公众号</p>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div>
        <div class="copyright-list">
          <!-- <a href="/">关于我们</a>
          <span></span>
          <a href="/">法律声明</a>
          <span></span>
          <a href="/">诚聘英才</a>
          <span></span>
          <a href="/">投资洽谈</a>
          <span></span>
          <a href="/">联系我们</a>
          <span></span>
          <a href="/">资讯研报</a>
          <span></span>
          <a href="/">工商执照</a>
          <span></span>
          <a href="/">舞弊举报</a> -->
        </div>
        <div class="copyright-content">
          <a href="https://beian.miit.gov.cn/" target=""
            >版权所有2021 中国进出口商品韶关全球商品交易平台 粤ICP备
            2022024635号  增值电信业务经营许可证：粤B2-20221132
          </a>
          <!-- <a href="">粤公网安备 号</a> -->
        </div>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div style="text-align: center">
        <img v-lazy="require('@/assets/images/QR-code.png')" alt="" />
        <p style="font-size:20px">关注官方微信公众号</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "app-footer",

  components: {},
  data() {
    return {
      nav: [
        {
          title: "帮助中心",
          children: [
            {
              title: "在线客服",
            },
            // {
            //   title: "买家指南",
            //   href: "/Agreement/RegistrationOperationGuide.pdf",
            // },
            {
              title: "卖家指南",
              href: "/Agreement/ShopGuide.pdf",
            },
          ],
        },
        {
          title: "平台规则",
          children: [
            {
              title: "服务协议",
              href: "/Agreement/ServiceAgreement.pdf",
            },
            // {
            //   title: "交易规则",
            // },
            {
              title: "隐私条款",
              href: "/Agreement/ImplicitPolicy.pdf",
            },
          ],
        },
        // {
        //   title: "合同模板",
        //   children: [
        //     {
        //       title: "采购合同",
        //     },
        //     {
        //       title: "销售合同",
        //     },
        //   ],
        // },
        // {
        //   title: "支付方式",
        //   children: [
        //     {
        //       title: "在线支付",
        //     },
        //     {
        //       title: "余额支付",
        //     },
        //   ],
        // },
      ],
      dialogVisible: false,
    };
  },

  mounted() {},

  methods: {
    operation(index, i) {
      switch (index) {
        case 0:
          switch (i) {
            case 0:
              this.dialogVisible = true;
              break;
            default:
              break;
          }
          break;
        case 1:
          break;
        case 2:
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/footer.scss";
</style>