<template>
  <div id="box">
    <app-header />
    <!-- <transition name="el-fade-in"> -->
      <!-- <keep-alive> -->
      <router-view />
      <!-- </keep-alive> -->
    <!-- </transition> -->
    <app-footer />
  </div>
</template>

<script>
import Header from "@/layout/Header";
import Footer from "@/layout/Footer";
export default {
  components: {
    [Header.name]: Header,
    [Footer.name]: Footer,
  },
  data() {
    return {};
  },

  created() {
    console.log(process.env);
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
// #box {
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   position: relative;
//    top: 0;
//   bottom: 0;
// }
</style>